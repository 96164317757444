/* color: 
    black - #4C3D3D
    gold - #C07F00
    yellow - #FFD95A
    background - #FFF7D4

    grey scale:
        #666666
        #d7d7d7
        #acacac
        #a4a4ac

    fonts:
        font-family: 'Great Vibes', cursive;
        font-family: 'Open Sans', sans-serif;
*/

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  color: #4c3d3d;
  font-family: "Open Sans", sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  font-size: 16px;
  width: 70%;
}

nav div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: 100%;
  color: #4c3d3d;
}

.logo {
  justify-content: flex-start;
}

.logo img {
  height: 1.5rem;
  width: auto;
}

.nav-links-active {
  display: flex;
  justify-content: flex-end;
}

.nav-links-active p,
.nav-links-active a {
  text-decoration: none;
  justify-content: flex-end;
  margin: 0 0.5rem;
  font-weight: 400;
  color: #4c3d3d;
}

.burger-icon-active {
  display: none;
}

.nav-links-mobile-active {
  display: none;
}

.nav-links-mobile-notActive {
  display: none;
}

.slider-container {
  max-width: 100%;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
}

.banner-about {
  background-image: url(https://images.unsplash.com/photo-1610748066641-5c5b28cdb01e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1496&q=80);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  width: 100%;
}

.banner-about-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
  /* color: #3a3a3a; */
}

.banner-about-info div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  /* margin-right: 5rem; */
}

.banner-menu-info a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  height: 2.5rem;
  width: 10rem;
  background-color: lightgray;
  color: #4c3d3d;
  border: 1px solid transparent;
  border-radius: 25px;
}

.banner-menu-info a:hover {
  background-color: grey;
  color: white;
  transition: 500ms;
}

.banner-menu {
  background-image: url(https://images.unsplash.com/photo-1490818387583-1baba5e638af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  width: 100%;
}

.banner-menu-info {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.3); */
  height: 50vh;
  width: 100%;
}

.banner-menu-info div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  /* margin-right: 5rem; */
  color: #4c3d3d;
}

.banner-contact {
  background-image: url(https://images.unsplash.com/photo-1613727798351-6873d1836998?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
}

.banner-contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  height: 50vh;
  width: 100%;
}

.banner-contact-info div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  /* margin-right: 5rem; */
  color: #fff7d4;
}

.banner-contact-info a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  height: 2.5rem;
  width: 10rem;
  background-color: #fff7d4;
  color: #4c3d3d;
  border: 1px solid transparent;
  border-radius: 25px;
}

.banner-contact-info a:hover {
  background-color: grey;
  color: white;
  transition: 500ms;
}

.specials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  height: 100%;
  margin-top: 5rem;
}

.specials-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.arrow-down {
  margin: 0 1rem;
  color: lightgray;
}

.specials-card-body {
  border: 1px solid #d3d3d3;
  width: 18.5rem;
  height: 30rem;
  margin: 2rem 0rem;
  box-shadow: 6px 6px 0px -1px #d3d3d3;
}

.specials-card-body:hover {
  box-shadow: 6px 6px 0px -1px transparent;
  transition: 200ms;
}

.specials-card-body img {
  width: 100%;
  height: 12rem;
}

.specials-card-details {
  padding: 1rem;
}

.specials-card-details h3 {
  text-align: center;
  margin-bottom: 2rem;
}

.specials-card-details p {
  text-align: center;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 10rem;
}

.menu-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  height: 7rem;
  margin: 5rem;
}

.menu-button-default {
  width: 24%;
  height: 2.5rem;
  outline-color: transparent;
  font-size: 1.2rem;
  border-radius: 1px;
  background-color: white;
  border: 1px solid #d3d3d3;
  box-shadow: 6px 6px 0px -1px #d3d3d3;
}

.menu-button-active {
  width: 24%;
  height: 2.5rem;
  outline-color: transparent;
  font-size: 1.2rem;
  border-radius: 1px;
  background-color: white;
  border: 1px solid #d3d3d3;
  transition: 200ms;
  /* box-shadow: 6px 6px 0px -1px #d3d3d3; */
}

.menu-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  margin: auto;
}

.menu-card-body {
  display: flex;
  border: 1px solid #d3d3d3;
  box-shadow: 6px 6px 0px -1px #d3d3d3;
  margin: 2rem 0;
  width: 100%;
}

.menu-card-body:hover {
  box-shadow: 6px 6px 0px -1px transparent;
  transition: 200ms;
}

.menu-card-body img {
  min-width: 27rem;
  min-height: 15.5rem;
  max-width: 27rem;
  max-height: 15.5rem;
  margin-right: 2rem;
}

.menu-card-details {
  width: 100%;
  padding-right: 2rem;
  margin-right: auto;
}

.menu-card-details div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: auto;
}

.description {
  height: 7rem;
}

.price {
  margin-right: 2rem;
}

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(https://images.unsplash.com/photo-1551499779-ee50f1aa4d25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  margin: 5rem 0;
}

.about-container {
  width: 70%;
}

.about h1 {
  text-align: center;
  margin: 5rem 0;
}

.about p {
  text-align: justify;
  margin-bottom: 11rem;
  /* text-shadow: 0px 0px 10px #4C3D3D; */
  color: #4c3d3d;
}

.contact {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 5rem 0;
  box-shadow: 6px 6px 0px -1px #d3d3d3;
  border: 1px solid #d3d3d3;
}

.contact h1 {
  text-align: center;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 2rem 0;
}

.contact-info div {
  width: 90%;
}

.contact-info p {
  font-weight: 500;
  font-size: 1.2rem;
  text-align: justify;
}

.contact-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 4rem 0;
}

.contact-icons div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.contact-info i {
  color: #4c3d3d;
  font-size: 1.5rem;
  width: 3rem;
}

.contact-icons a,
.contact-icons p {
  color: #4c3d3d;
  text-decoration: none;
  font-size: 1.2rem;
}

.contact-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
}

.contact-input input,
.contact-input textarea {
  width: 90%;
  margin: 1rem 0;
  padding-left: 2rem;
  border: 1px solid #d3d3d3;
  color: #4c3d3d;
}

.input-false {
  outline-color: red;
}

.input-true {
  outline-color: green;
}

.contact-input input {
  height: 2.5rem;
}

.contact-input textarea {
  height: 15rem;
  padding-top: 2rem;
}

.contact-button-true {
  height: 2rem;
  width: 10rem;
  background-color: lightgray;
  border: 1px solid #4c3d3d;
  color: #4c3d3d;
  margin-bottom: 1rem;
}

.contact-button-false {
  height: 2rem;
  width: 10rem;
  background-color: white;
  border: 1px solid #d3d3d3;
  color: #d3d3d3;
  margin-bottom: 1rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3d3d3;
  width: 100%;
  height: 100%;
}

.footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  height: 100%;
}

.footer-container div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 32%;
  height: 100%;
  /* border: 1px solid white; */
}

.footer-container a {
  text-decoration: none;
  color: #4c3d3d;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3d3d3;
  width: 100%;
}

.copyright p {
  text-align: center;
  width: 70%;
  font-size: 0.8rem;
}

@media screen and (max-width: 1024px) {
  nav,
  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div,
  .specials,
  .menu-nav,
  .menu-content,
  .about-container,
  .contact,
  .footer-container {
    width: 80%;
  }

  .contact-info,
  .contact-input {
    width: 50%;
  }

  .contact-input input,
  .contact-input textarea {
    width: 85%;
  }

  .specials-data {
    justify-content: space-around;
  }

  .menu-card-body img {
    min-width: 22rem;
    min-height: 15.5rem;
    max-width: 22rem;
    max-height: 15.5rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  p {
    font-size: 14px;
  }

  nav,
  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div,
  .specials,
  .menu-nav,
  .menu-content,
  .about-container,
  .contact,
  .footer-container {
    width: 90%;
  }

  .nav-links p {
    font-size: 14px;
  }

  .menu-nav button {
    font-size: 16px;
  }

  .menu-card-body img {
    min-width: 19rem;
    min-height: 13rem;
    max-width: 19rem;
    max-height: 13rem;
    margin-right: 1rem;
  }

  .description {
    font-size: 14px;
    height: 5rem;
  }

  .contact-info p {
    font-size: 1rem;
  }

  .footer-container div {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .banner-about-info div {
    color: white;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
    margin: 0;
  }

  h2,
  h3 {
    font-size: 1rem;
  }

  .nav-links-active {
    display: none;
    justify-content: flex-end;
  }

  .nav-links-notActive {
    display: none;
  }

  .burger-icon-active {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
  }

  .nav-links-mobile-active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: fixed;
    top: 3rem;
    left: 0px;
    z-index: 1;
    width: 99%;
    height: auto;
    background-color: white;
    box-shadow: 0px 11px 15px -4px #3a3a3a;
    margin-bottom: 5rem;
    padding-left: 3.2rem;
  }

  .nav-links-mobile-active a,
  .nav-links-mobile-active p {
    font-weight: 500;
    text-decoration: none;
    color: #4c3d3d;
    width: 100%;
  }

  .nav-links-mobile-active h5 {
    width: 100%;
    margin: 0.5rem 0;
  }

  .nav-links-mobile-active i {
    width: 2rem;
  }

  .nav-links-mobile-notActive {
    display: none;
  }

  nav,
  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div,
  .specials,
  .menu-nav,
  .menu-content,
  .about-container,
  .contact,
  .footer-container {
    width: 90%;
  }

  .navbar {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
  }

  .sm-residences-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .sm-residences-links a {
    width: 3rem;
  }

  .sm-residences-links p {
    width: auto;
    margin-right: 2rem;
  }

  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div {
    align-items: center;
  }

  .banner,
  .banner-about,
  .banner-about-info,
  .banner-menu,
  .baner-menu-info,
  .banner-contact,
  .banner-contact-info {
    height: 50vh;
  }

  .banner-menu {
    background-position: right;
  }

  .banner-about-info {
    color: white;
  }

  .specials-card-body {
    width: 46%;
    box-shadow: none;
    border: 1px solid #d3d3d3;
    height: 27rem;
  }

  .specials-card-body img {
    width: 100%;
    height: 11rem;
  }

  .specials-card-details h3 {
    text-align: left;
  }

  .specials-card-details p {
    text-align: justify;
  }

  .menu-nav {
    margin: 1rem;
  }

  .menu-button-default {
    box-shadow: none;
    font-size: 14px;
  }

  .menu-button-active {
    background-color: white;
    border: 1px solid #4c3d3d;
    font-size: 14px;
    transition: 200ms;
  }

  .menu-card-body {
    flex-direction: row;
    border: 1px solid transparent;
    box-shadow: none;
    border: 1px solid #d3d3d3;
  }

  .menu-card-body img {
    min-width: 40%;
    min-height: auto;
    max-width: 40%;
    max-height: auto;
    margin-right: 1rem;
  }

  .menu-card-details {
    padding-right: 1rem;
  }

  .description {
    height: auto;
  }

  .contact {
    flex-direction: column;
    width: 90%;
    margin: 0;
    box-shadow: none;
    border: none;
  }

  .contact h1 {
    margin: 0;
  }

  .contact-info {
    justify-content: center;
    width: 100%;
  }

  .contact-info div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-icons {
    margin: 0;
  }

  .contact-icons div {
    flex-direction: row;
    margin: 0 5rem;
  }

  .contact-icons a,
  .contact-icons p {
    font-size: 16px;
    text-align: center;
  }

  .contact-input {
    width: 100%;
    margin: 0 0 5rem 0;
  }

  .contact-input input,
  .contact-input textarea {
    width: 90%;
  }

  .footer-container {
    width: 90%;
  }

  .footer-container div {
    align-items: flex-start;
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  h1 {
    font-size: 1.5rem;
    margin: 0;
  }

  h2,
  h3 {
    font-size: 1rem;
  }

  /* .hr {
        border: 1px solid #d3d3d3;
        width: 80%;
    } */

  .nav-links-active {
    display: none;
    justify-content: flex-end;
  }

  .nav-links-notActive {
    display: none;
  }

  .burger-icon-active {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
  }

  .nav-links-mobile-active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: fixed;
    top: 3rem;
    left: 0px;
    z-index: 1;
    width: 99%;
    height: auto;
    background-color: white;
    box-shadow: 0px 11px 15px -4px #3a3a3a;
    margin-bottom: 5rem;
    padding-left: 3.2rem;
  }

  .nav-links-mobile-active a,
  .nav-links-mobile-active p {
    font-weight: 500;
    text-decoration: none;
    color: #4c3d3d;
    width: 100%;
  }

  .nav-links-mobile-active h5 {
    width: 100%;
    margin: 0.5rem 0;
  }

  .nav-links-mobile-active i {
    width: 2rem;
  }

  .nav-links-mobile-notActive {
    display: none;
  }

  nav,
  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div,
  .specials,
  .menu-nav,
  .menu-content,
  .about-container,
  .contact,
  .footer-container {
    width: 90%;
  }

  .navbar {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
  }

  .sm-residences-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .sm-residences-links a {
    width: 3rem;
  }

  .sm-residences-links p {
    width: auto;
    margin-right: 2rem;
  }

  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div {
    align-items: center;
  }

  .banner,
  .banner-about,
  .banner-about-info,
  .banner-menu,
  .baner-menu-info,
  .banner-contact,
  .banner-contact-info {
    height: 75vh;
  }

  .banner-menu {
    background-position: right;
  }

  .banner-about-info {
    color: white;
  }

  .specials-card-body {
    width: 100%;
    box-shadow: none;
    border: 1px solid #d3d3d3;
  }

  .specials-card-body img {
    width: 100%;
    height: 15rem;
  }

  .specials-card-details h3 {
    text-align: left;
  }

  .specials-card-details p {
    text-align: justify;
  }

  .menu-nav {
    margin: 1rem;
  }

  .menu-button-default {
    box-shadow: none;
    font-size: 14px;
  }

  .menu-button-active {
    background-color: white;
    border: 1px solid #4c3d3d;
    font-size: 14px;
    transition: 200ms;
  }

  .menu-card-body {
    flex-direction: column;
    border: 1px solid transparent;
    box-shadow: none;
    border-bottom: 1px solid #d3d3d3;
  }

  .menu-card-body img {
    min-width: 100%;
    min-height: 13rem;
    max-width: 100%;
    max-height: 13rem;
    margin-right: 1rem;
  }

  .description {
    height: auto;
  }

  .contact {
    flex-direction: column;
    width: 90%;
    margin: 0;
    box-shadow: none;
    border: none;
  }

  .contact h1 {
    margin: 0;
  }

  .contact-info {
    justify-content: center;
    width: 100%;
  }

  .contact-info div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-icons {
    margin: 0;
  }

  .contact-icons div {
    flex-direction: row;
    margin: 0 5rem;
  }

  .contact-icons a,
  .contact-icons p {
    font-size: 16px;
    text-align: center;
  }

  .contact-input {
    width: 100%;
    margin: 0 0 5rem 0;
  }

  .contact-input input,
  .contact-input textarea {
    width: 90%;
  }

  .footer-container {
    width: 90%;
  }

  .footer-container div {
    align-items: flex-start;
    width: 50%;
  }
}

@media only screen and (min-width: 450px) and (max-width: 480px) {
  /* body {
        background-color: red;
    } */

  h1 {
    font-size: 1.5rem;
    margin: 0;
  }

  h2,
  h3 {
    font-size: 1rem;
  }

  /* .hr {
        border: 1px solid #d3d3d3;
        width: 80%;
    } */

  .nav-links-active {
    display: none;
    justify-content: flex-end;
  }

  .nav-links-notActive {
    display: none;
  }

  .burger-icon-active {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
  }

  .nav-links-mobile-active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: fixed;
    top: 3rem;
    left: 0px;
    z-index: 1;
    width: 77%;
    height: auto;
    background-color: white;
    box-shadow: 0px 20px 20px 0px #3a3a3a40;
    margin-bottom: 5rem;
    padding: 0 3.2rem;
  }

  .nav-links-mobile-active a,
  .nav-links-mobile-active p {
    font-weight: 500;
    text-decoration: none;
    color: #4c3d3d;
    width: 100%;
  }

  .nav-links-mobile-active h5 {
    width: 100%;
    margin: 0.5rem 0;
  }

  .nav-links-mobile-active a:hover {
    text-decoration: underline;
  }

  .nav-links-mobile-active i {
    width: 2rem;
  }

  .nav-links-mobile-notActive {
    display: none;
  }

  nav,
  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div,
  .specials,
  .menu-nav,
  .menu-content,
  .about-container,
  .contact,
  .footer-container {
    width: 90%;
  }

  .navbar {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
  }

  .sm-residences-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .sm-residences-links a {
    width: 3rem;
  }

  .sm-residences-links p {
    width: auto;
    margin-right: 2rem;
  }

  .banner-about-info div,
  .banner-menu-info div,
  .banner-contact-info div {
    align-items: center;
  }

  .banner,
  .banner-about,
  .banner-about-info,
  .banner-menu,
  .baner-menu-info,
  .banner-contact,
  .banner-contact-info {
    height: 50vh;
  }

  .banner-menu {
    background-position: right;
  }

  .banner-about-info {
    color: white;
  }

  .specials-card-body {
    width: 100%;
    height: auto;
    box-shadow: none;
    border: 1px solid #d3d3d3;
  }

  .specials-card-body img {
    width: 100%;
    height: 15rem;
  }

  .specials-card-details h3 {
    text-align: left;
    margin: 0;
  }

  .specials-card-details p {
    text-align: justify;
  }

  .menu-nav {
    margin: 1rem;
  }

  .menu-button-default {
    box-shadow: none;
    font-size: 14px;
  }

  .menu-button-active {
    background-color: white;
    border: 1px solid #4c3d3d;
    font-size: 14px;
    transition: 200ms;
  }

  .menu-card-body {
    flex-direction: column;
    border: 1px solid transparent;
    box-shadow: none;
    border-bottom: 1px solid #d3d3d3;
  }

  .menu-card-body img {
    min-width: 100%;
    min-height: 13rem;
    max-width: 100%;
    max-height: 13rem;
    margin-right: 1rem;
  }

  .description {
    height: auto;
  }

  .contact {
    flex-direction: column;
    width: 90%;
    margin: 0;
    box-shadow: none;
    border: none;
  }

  .contact h1 {
    margin: 0;
  }

  .contact-info {
    justify-content: center;
    width: 100%;
  }

  .contact-info div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-icons {
    margin: 0;
  }

  .contact-icons div {
    flex-direction: row;
    margin: 0 5rem;
  }

  .contact-icons a,
  .contact-icons p {
    font-size: 16px;
    text-align: center;
  }

  .contact-input {
    width: 100%;
    margin: 0 0 5rem 0;
  }

  .contact-input input,
  .contact-input textarea {
    width: 90%;
  }

  .footer-container {
    width: 90%;
  }

  .footer-container div {
    align-items: flex-start;
    width: 50%;
  }
}
